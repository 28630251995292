import React from 'react';
import { Switch, Route } from 'react-router-dom';

import UserContext from './context/user';

// import PopularRepos from './components/PopularRepos';
import ScrollToTop from './components/helpers/ScrollToTop';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Header';
// import Footer from './components/Footer';

import FrontPage from './pages/FrontPage';
import City from './pages/City';
import Cities from './pages/Cities';
import Artist from './pages/Artist';
import Artists from './pages/Artists';
import Event from './pages/Event';
import Events from './pages/Events';
import Venue from './pages/Venue';
import Venues from './pages/Venues';
import Genre from './pages/Genre';
import Genres from './pages/Genres';
import Profile from './pages/Profile';
import SignUp from './pages/SignUp';
import LogIn from './pages/LogIn';
import ProfileSettings from './pages/ProfileSettings';
import Messages from './pages/Messages';
import Sandbox from './pages/Sandbox';
import Bookmarks from './pages/Bookmarks';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';

import './App.css';

const defaultProps = {};

const Discover = ({ match }) => {
  return (
    <Switch>
      <Route path={match.url + '/cities'} component={Cities} />
      <Route path={match.url + '/artists'} component={Artists} />
      <Route path={match.url + '/events'} component={Events} />
      <Route path={match.url + '/venues'} component={Venues} />
      <Route path={match.url + '/genres'} component={Genres} />
      <Route path={match.url + '/genre/:genre'} component={Genre} />
    </Switch>
  );
};

const BookmarksRoute = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.url} component={Bookmarks} />
      <Route path={match.url + '/artists'} component={Artists} />
      <Route path={match.url + '/events'} component={Events} />
      <Route path={match.url + '/venues'} component={Venues} />
    </Switch>
  );
};

const Root = props => {
  return (
    <div className="content">
      <div className="fixed-header">
        <Header />
      </div>

      <Switch>
        <Route exact path="/" component={FrontPage} />

        <Route path="/discover" component={Discover} />

        <Route path="/city/:id" component={City} />
        <Route path="/artist/:artist" component={Artist} />
        <Route path="/event/:event" component={Event} />
        <Route path="/venue/:venue" component={Venue} />

        <PrivateRoute path="/profile/settings" component={ProfileSettings} />
        <PrivateRoute path="/profile" component={Profile} />
        {/* <ArtistProfile */}
        <PrivateRoute path="/messages" component={Messages} />
        <PrivateRoute path="/events" component={Dashboard} />
        <PrivateRoute path="/bookmarks" component={BookmarksRoute} />
        <PrivateRoute path="/settings" component={Settings} />

        <Route path="/login" component={LogIn} />
      </Switch>
    </div>
  );
};

const App = props => {
  return (
    <ScrollToTop>
      <UserContext>
        <div>
          <Switch>
            <Route exact path="/sandbox" component={Sandbox} />
            <Route exact path="/become-an-artist" component={SignUp} />
            <Route
              exact
              path="/add-event"
              render={props => <SignUp type="event" />}
            />
            <Route
              exact
              path="/host-event"
              render={props => <SignUp type="venue" />}
            />
            <Route path="/" component={Root} />
          </Switch>
        </div>
        {/* <Footer /> */}
      </UserContext>
    </ScrollToTop>
  );
};

App.defaultProps = defaultProps;

export default App;
