import React from 'react';

import Section from '../components/Section';
import { FeaturedVenues } from '../components/sections/FeaturedVenues';
import { FeaturedEvents } from '../components/sections/FeaturedEvents';
import { ArtsitsGrid } from '../components/sections/ArtsitsGrid';
// import Cover from '../components/Cover';

import { artists, events, venues, genres } from '../placeholder';

const defaultProps = {
  artists,
  events,
  venues,
  genres,
};

const Bookmarks = ({ match, ...props }) => {
  return (
    <div>
      {/* <Cover image={city.image} title={city.title} /> */}
      <div className="condenced">
        <Section label="Saved Artists" path={match.url + '/artists'}>
          <ArtsitsGrid items={props.artists} />
        </Section>
        <Section label="Saved Events" path={match.url + '/events'}>
          <FeaturedEvents items={props.events} />
        </Section>
        <Section label="Saved Venues" path={match.url + '/venues'}>
          <FeaturedVenues items={props.venues} />
        </Section>
      </div>
    </div>
  );
};

Bookmarks.defaultProps = defaultProps;

export default Bookmarks;
