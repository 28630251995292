import React from 'react';

import { Logo } from '../components/Header';

import Image from '../components/Image';
import ArtistForm from '../components/forms/ArtistForm';
import EventForm from '../components/forms/EventForm';
import VenueForm from '../components/forms/VenueForm';

import artist from '../assets/artists/photo-1521335639660-c569b17006f5.jfif';
import event from '../assets/banners/photo-1520483691742-bada60a1edd6.jfif';
import venue from '../assets/banners/photo-1516137235045-2937f026afed.jfif';

const signup = {
  artist: {
    image: artist,
    form: <ArtistForm />,
  },
  event: {
    image: event,
    form: <EventForm />,
  },
  venue: {
    image: venue,
    form: <VenueForm />,
  },
};

const SignUp = ({ type = 'artist' }) => {
  return (
    <div
      className="flex"
      // style={
      //   {
      //     position: 'absolute',
      //     top: 0,
      //     zIndex: -1,
      //     width: '100%',
      //     height: '100vh'
      //   }
      // }
    >
      <div className="fixed-header">
        <header
          className="header flex alignCenter"
          style={{ border: 0, background: 'transparent' }}
        >
          <div className="container flex alignCenter justifyBetween">
            <Logo />
          </div>
        </header>
      </div>
      <Image
        src={signup[type].image}
        className="flexNone"
        style={{
          // height: '100%',
          width: '50%',
        }}
      />
      <div className="padding10">{signup[type].form}</div>
    </div>
  );
};

export default SignUp;
