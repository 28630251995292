import React from 'react';
import { Link } from 'react-router-dom';

import Image from '../Image';

import { gigs } from '../../placeholder';

const MessagesList = ({ items }) => {
  return items.map((chat, i) => {
    return (
      <Link
        to={`/messages/${chat.username.replace(' ', '_').toLowerCase()}`}
        key={i}
      >
        <div className="flex alignCenter padding2 dim">
          <Image className="ava flexNone" src={chat.image} />
          <div className="marginX2 fz14 overflowHidden">
            <div className="mb1">
              <strong>{chat.username}</strong>
            </div>
            <div className="truncate">{chat.message}</div>
          </div>
        </div>
      </Link>
    );
  });
};

MessagesList.defaultProps = {
  items: gigs,
};

export default MessagesList;
