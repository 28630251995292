import React from 'react';

import { Match } from '../components/helpers/Match';
import { artists } from '../placeholder';
import SectionLabel from '../components/SectionLabel';
import StickyFooter from '../components/FixedFooter';
// import { HeadingSection } from '../components/HeadingSection';
import Chip from '../components/Chip';
import genres from '../lists/genres.json';
import Schedule from '../components/sections/Schedule';
import { YoutubeVideo } from '../components/YoutubeVideo';

export const ArtistImages = ({ artist }) => {
  return (
    <div className="flex alignCenter overflowHidden">
      <div
        className="image rectangle flexNone"
        style={{
          height: 256,
          width: 400,
          backgroundImage: `url(${artist.image})`,
        }}
      />
      <div
        className="image avatar flexNone"
        style={{
          height: 256,
          width: 256,
          backgroundImage: `url(${artist.image})`,
        }}
      />
      <div
        className="image rectangle flexNone"
        style={{
          height: 256,
          width: 400,
          backgroundImage: `url(${artist.image})`,
        }}
      />
      <div
        className="image avatar flexNone"
        style={{
          height: 256,
          width: 256,
          backgroundImage: `url(${artist.image})`,
        }}
      />
      <div
        className="image rectangle flexNone"
        style={{
          height: 256,
          width: 400,
          backgroundImage: `url(${artist.image})`,
        }}
      />
    </div>
  );
};

const ArtistPage = ({ data: artist }) => {
  return (
    <div>
      <ArtistImages artist={artist} />
      <div className="page condenced">
        <div className="section">
          <div>
            <span className="heading bold">{artist.title}</span>
            <span
              className="text ttu c-gray ms4"
              style={{
                fontSize: 26,
              }}
            >
              {artist.city + ', ' + artist.country}
            </span>
          </div>
          <p className="text">
            {artist.links.map(link => {
              return (
                <a
                  className="a-link"
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={link.url}
                >
                  {link.label}
                </a>
              );
            })}
          </p>
          <div className="tags mt6">
            {artist.tags.map(tag => (
              <Chip {...genres[tag]} key={tag} />
            ))}
          </div>
        </div>
        <div className="section">
          <SectionLabel title="Bio" />
          <p className="text color-gray">{artist.about}</p>
          <br />
          <YoutubeVideo src="https://www.youtube-nocookie.com/embed/nxg4C365LbQ" />
          <br />
          <p className="text">
            <b>Language:</b> {artist.lang.join(', ')}
          </p>
          <p className="text">
            <b>Members:</b> {artist.members.join(', ')}
          </p>
          <p className="text">
            <b>Rider:</b>
          </p>
          <ul className="text color-gray padding0">
            <li>Lorem ipsum dolor sit amet.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
            <li>Lorem ipsum dolor, sit amet consectetur adipisicing.</li>
            <li>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Blanditiis, veniam voluptate.
            </li>
            <li>Lorem, ipsum dolor.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
            <li>Lorem ipsum dolor sit.</li>
            <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
            <li>Lorem ipsum dolor sit amet.</li>
            <li>Lorem, ipsum dolor.</li>
          </ul>
        </div>
        <div className="section">
          <SectionLabel title="Schedule" />
          <Schedule />
        </div>
        <StickyFooter />
      </div>
    </div>
  );
};

const Artist = props => {
  return (
    <Match param={'artist'} data={artists} {...props}>
      <ArtistPage />
    </Match>
  );
};

export default Artist;
