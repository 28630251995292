import React from 'react';
// import cc from 'classcat';

import styles from './Input.module.css';

const Select = ({ children, placeholder, ...props }) => {
  return (
    <select
      className={[
        styles.base,
        styles.input,
        styles.select,
        !!props.value ? styles.selected : ''
      ].join(' ')}
      name="select"
      {...props}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {children}
    </select>
  );
};

export default Select;
