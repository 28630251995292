import { useEffect } from 'react';

export const useClickOutside = (ref, onClickOutside) => {
  useEffect(() => {
    const handler = ev => {
      const { current: el } = ref;
      el && !el.contains(ev.target) && onClickOutside(ev);
    };
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  });
};
