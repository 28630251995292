import React from 'react';
import { Link } from 'react-router-dom';

import CityCard from '../cards/CityCard';

const GenresSection = ({ items, prefix = '/genre' }) => {
  return (
    <div className="flex columns">
      {items.map((genre, i) => (
        <div
          className="column grid4"
          style={{ height: '140px' }}
          key={genre.path}
        >
          <Link to={prefix + genre.path}>
            <CityCard
              image={genre.image}
              title={genre.title}
              key={genre.title}
            />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default GenresSection;
