import React from 'react';
import EventCard from '../cards/EventCard';

export const EventsGrid = props => {
  return (
    <div className="grid" style={{ gridRowGap: 80 }}>
      {props.items.map((event, i) => (
        <div className="grid-item" key={event.title}>
          <EventCard
            path={'/event' + event.path}
            image={event.image}
            title={event.title}
            date={event.date}
            location={event.location}
            key={event.title}
          />
        </div>
      ))}
    </div>
  );
};
