import React from 'react';
import { Link } from 'react-router-dom';

import Square from '../Square';
import Chip from '../Chip';
import BookmarkButton from '../BookmarkButton';

import genres from '../../lists/genres.json';

const ArtistCard = ({ image, title, country, tags, path }) => {
  return (
    <div className="tac">
      <div className="padding5">
        <div className="relative trigger">
          <Link to={`/artist${path}`}>
            <Square>
              <div
                className="avatar image"
                style={{ backgroundImage: `url(${image})` }}
              />
            </Square>
          </Link>

          <div
            className="bookmark"
            style={{
              top: '50%',
              right: '50%',
              transform: 'translate3d(50%,-50%,0)',
            }}
          >
            <BookmarkButton />
          </div>
        </div>
      </div>
      <div className="h3">
        <Link to={`/artist${path}`}>{title}</Link>{' '}
        <span className="c-gray"> {country}</span>
      </div>
      {tags && (
        <div className="mt4">
          {tags.map(tag => (
            <Chip {...genres[tag]} key={tag} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ArtistCard;
