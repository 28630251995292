import React from 'react';

import { genres } from '../placeholder';
import FeaturedCities from '../components/sections/FeaturedCities';

const Genres = props => {
  return (
    <div className="container">
      <FeaturedCities items={props.genres} prefix="/discover/genre" />
    </div>
  );
};

Genres.defaultProps = {
  genres,
};

export default Genres;
