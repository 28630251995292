import React from 'react';
import Select from '../inputs/Select';

import countries from '../../lists/countries.json';

const CountrySelect = props => {
  return (
    <Select name="country" placeholder="Country" {...props}>
      {countries.map(country => (
        <option value={country.name} key={country.name}>
          {country.name}
        </option>
      ))}
    </Select>
  );
};

export default CountrySelect;
