import React from 'react';

import SectionLabel from '../SectionLabel';
import Input from '../inputs/Input';

const LinksForm = ({ inputs: { text } }) => {
  return (
    <>
      <SectionLabel title="Links" />
      <Input placeholder="Website" {...text('website')} />
      <Input placeholder="Spotify" {...text('spotify')} />
      <Input placeholder="Other" {...text('other')} />
    </>
  );
};

export default LinksForm;
