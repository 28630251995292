import React from 'react';

import input from './Input.module.css';
// import layout from '../../Layout.module.css';

const TextArea = props => (
  <div className="relative">
    <textarea
      className={`${input.base} ${input.textarea}`}
      rows="3"
      {...props}
    />
    {props.maxLength && props.value !== undefined && (
      <div className={input.counter}>
        {props.maxLength - props.value.length}
      </div>
    )}
  </div>
);

export default TextArea;
