import React from 'react';

import Image from '../Image';
// import './ActivityFeed.css';

import { activity } from '../../placeholder';

const ActivityFlyout = ({ height = 'auto', items }) => {
  return (
    <div className="popup" style={{ height }}>
      {items.map((act, i) => {
        return (
          <div className="flex alignCenter padding2" key={i}>
            <Image className="ava flexNone" src={act.image} />
            <div className="marginX2 fz14">
              <strong>{act.username}</strong> {act.message}{' '}
              {act.event && <strong>{act.event}</strong>}
              {act.venue && <strong>{act.venue}</strong>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

ActivityFlyout.defaultProps = {
  items: activity,
};

export default ActivityFlyout;
