import React from 'react';

import { events } from '../placeholder';
import { EventsGrid } from '../components/sections/EventsGrid';
import Filter from '../components/filters/Filter';
import Banner from '../components/banners/Banner';

import banner from '../assets/banners/photo-1520483691742-bada60a1edd6.jfif';

const Events = props => {
  return (
    <div>
      <div className="container">
        <div style={{ margin: '24px 0 44px' }}>
          <Filter params={['Date', 'Genre', 'Country', 'Venue']} />
        </div>

        <div className="section">
          <EventsGrid items={props.events} />
        </div>
      </div>
      <Banner image={banner} title="Oragnize an Event" path="/add-event" />
    </div>
  );
};

Events.defaultProps = {
  events: events.slice(0, 7),
};

export default Events;
