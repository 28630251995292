import React from 'react';

import ArtistCard from '../cards/ArtistCard';

export const FeaturedArtists = ({ items, cols }) => {
  return (
    <div
      className="grid"
      style={{ ...(cols && { gridTemplateColumns: `repeat(${cols}, 1fr)` }) }}
    >
      {items.map((artist, i) => (
        <div className="grid-item" key={artist.path}>
          <ArtistCard
            path={artist.path}
            image={artist.image}
            title={artist.title}
            country={artist.country}
            // tags={artist.tags}
            key={artist.title}
          />
        </div>
      ))}
    </div>
  );
};
