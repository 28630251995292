import React from 'react';
import { Link } from 'react-router-dom';

import { events } from '../placeholder';
import CalendarDay from '../components/CalendarDay';
import EventCard from '../components/cards/EventCard';
import Button from '../components/Button';

const Calendar = ({ events, onClick }) => {
  return (
    <div className="table">
      <div className="table__row line bold">
        <div className="table__cell">Date</div>
        <div className="table__cell">Event</div>
        <div className="table__cell">Location</div>
        <div className="table__cell">Offer</div>
        <div className="table__cell" />
      </div>
      {events.map((event, i) => {
        return (
          <div
            className="table__row line cup"
            onClick={() => onClick(i)}
            key={i}
          >
            <div className="table__cell padding0">
              {/* {event.date.substring(8, 10) + '/' + event.date.substring(5, 7)} */}
              <CalendarDay date={event.startDate} />
            </div>
            <div className="table__cell">
              {/* <Link to={'/event' + event.path}>{event.title}</Link> */}
              {event.title}
            </div>
            <div className="table__cell">{event.location}</div>
            <div className="table__cell">100</div>
            <div className="table__cell">
              <div className="flex justifyEnd">
                <div className="marginX4">Decline</div>
                <div className="marginX4">Accept</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const MyEvents = ({ match, events }) => {
  const [selected, select] = React.useState(null);
  return (
    <div className="container">
      <div className="flex justifyBetween mt6 mb10">
        <div className="spacing6">
          <Button text="Date" />
          <Button text="Filter" />
          <Button text="View" />
        </div>
        <div>
          <Button text="Add Event" />
        </div>
      </div>
      <div className="flex">
        <Calendar events={events} onClick={select} />

        {selected !== null && (
          <div
            className="flexNone flex flexColumn justifyBetween paddingX6"
            style={{ width: '25%' }}
          >
            <EventCard
              {...events[selected]}
              path={'/event' + events[selected].path}
            />
            {/* <Link to={match.url + '/lani'}>
              <Button block>View Offer</Button>
            </Link> */}
          </div>
        )}
      </div>
    </div>
  );
};

MyEvents.defaultProps = {
  events,
};

export default MyEvents;
