import { photos } from './photos';

export const gigs = [
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/198/cat-face_1f431.png',
    image: photos[0].urls.thumb,
    username: 'Yellow Cat',
    message: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
    date: '22 May 2019',
    time: '21:00',
    offer: 100,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/fox-face_1f98a.png',
    image: photos[1].urls.thumb,
    username: 'Orange Fox',
    message: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
    date: '22 May 2019',
    time: '21:00',
    offer: 240,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/panda-face_1f43c.png',
    image: photos[2].urls.thumb,
    username: 'Panda Face',
    message: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
    date: '22 May 2019',
    time: '21:00',
    offer: 60,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/frog-face_1f438.png',
    image: photos[3].urls.thumb,
    username: 'Zabka',
    message: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
    date: '22 May 2019',
    time: '21:00',
    offer: 180,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/rabbit-face_1f430.png',
    image: photos[4].urls.thumb,
    username: 'Rabbit',
    message: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
    date: '22 May 2019',
    time: '21:00',
    offer: 80,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/198/cat-face_1f431.png',
    image: photos[5].urls.thumb,
    username: 'Yellow Cat',
    message: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
    date: '22 May 2019',
    time: '21:00',
    offer: 300,
    event: 'Event',
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/fox-face_1f98a.png',
    image: photos[6].urls.thumb,
    username: 'Orange Fox',
    message: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
    date: '22 May 2019',
    time: '21:00',
    offer: 140,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/panda-face_1f43c.png',
    image: photos[7].urls.thumb,
    username: 'Panda Face',
    message: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
    date: '22 May 2019',
    time: '21:00',
    offer: 350,
    venue: 'Venue',
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/frog-face_1f438.png',
    image: photos[8].urls.thumb,
    username: 'Zabka',
    message: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
    date: '22 May 2019',
    time: '21:00',
    offer: 50,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/rabbit-face_1f430.png',
    image: photos[9].urls.thumb,
    username: 'Rabbit',
    message: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
    date: '22 May 2019',
    time: '21:00',
    offer: 100,
  },
];
