import React from 'react';
import './CalendarDay.css';

const CalendarDay = ({ date }) => {
  const d = new Date(date);
  const mt = d.toLocaleString('en-us', { month: 'short' });
  const dt = d.getDate();
  return (
    <div className="cday">
      <div className="cday__month bold">{mt}</div>
      <div className="cday__date">{dt}</div>
    </div>
  );
};

export default CalendarDay;
