import React from 'react';
import { Link } from 'react-router-dom';

import Square from '../Square';
import CityCard from '../cards/CityCard';

export const FeaturedCities = ({ items, prefix = '/city' }) => {
  return (
    <div className="flex columns">
      {items.map((city, i) => (
        <div className="flexNone column grid4" key={city.path}>
          <Link to={prefix + city.path}>
            <Square>
              <CityCard
                image={city.image}
                title={city.title}
                totalArtists={city.totalArtists}
                totalEvents={city.totalEvents}
                key={city.title}
              />
            </Square>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default FeaturedCities;
