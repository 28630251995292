import React from 'react';

// import { genres } from '../../lists/genres';
import g from '../../lists/genres.json';
import Chip from '../Chip';

// const GenresInput = ({ inputs: { checkbox } }) => {
//   return (
//     <div className="flex flexColumn flexWrap" style={{ height: 100 }}>
//       {genres.map(genre => {
//         return (
//           <label key={genre}>
//             <input type="checkbox" {...checkbox('genre', genre)} />
//             <span className="bold">{genre}</span>
//           </label>
//         );
//       })}
//     </div>
//   );
// };

const GenresInput = ({ inputs: { checkbox } }) => {
  return (
    <div className="flex flexWrap">
      {Object.keys(g).map(genre => {
        console.log(genre);
        return (
          <label key={genre}>
            <input type="checkbox" hidden {...checkbox('genre', genre)} />
            <Chip {...g[genre]} />
          </label>
        );
      })}
    </div>
  );
};

export default GenresInput;
