import ea from '../assets/events/6c790f1b6ed8a5a00e3bcee28ede4fba.jpg';
import eb from '../assets/events/73.jpg';
import ec from '../assets/events/04bb9594a871a9b6541c319e5907c456.jpg';
import ed from '../assets/events/_lkSqQXX-5I.jpg';
import eh from '../assets/events/83ecf4b9dd78296d21745e5a4edc4856.jpg';
import ei from '../assets/events/169206_382135941875791_2007966827_o.jpg';
import ej from '../assets/events/8543418864_a125e0d4a1_b.jpg';
import ek from '../assets/events/1396894374902.jpg';

const about =
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, vitae odio maiores exercitationem earum nesciunt impedit eos excepturi sit facilis ipsa fugiat dolore cupiditate reiciendis consectetur, odit quia. Facere culpa, inventore, iste laudantium perferendis a dolores itaque quas fugit doloremque pariatur tenetur mollitia?';
const address = 'Schwedter Str. 17, 10119 Berlin, Germany';

export const events = [
  {
    path: '/sweet-spot-festival',
    title: 'Sweet Spot Festival',
    image: ea,
    date: '15 - 18 MAY 2019',
    startDate: '2019-05-29T10:05:50.999Z',
    endDate: '2019-05-29T10:05:50.999Z',
    location: 'Berlin',
    country: 'Germany',
    about: about,
    address: address,
  },
  {
    path: '/enter-the-36-chambers',
    title: 'Enter The 36 Chambers',
    image: eb,
    date: '15 - 18 MAY 2019',
    startDate: '2019-05-29T10:05:50.999Z',
    endDate: '2019-05-29T10:05:50.999Z',
    location: 'Berlin',
    country: 'Germany',
    about: about,
    address: address,
  },
  {
    path: '/lovebox-festival-2019',
    title: 'Lovebox Festival 2019',
    image: ec,
    date: '15 - 18 MAY 2019',
    startDate: '2019-05-29T10:05:50.999Z',
    endDate: '2019-05-29T10:05:50.999Z',
    location: 'Berlin',
    country: 'Germany',
    about: about,
    address: address,
  },
  {
    path: '/trnsmt-festival-2019',
    title: 'TRNSMT Festival 2019',
    image: ed,
    date: '15 - 18 MAY 2019',
    startDate: '2019-05-29T10:05:50.999Z',
    endDate: '2019-05-29T10:05:50.999Z',
    location: 'Berlin',
    country: 'Germany',
    about: about,
    address: address,
  },
  {
    path: '/the-origins',
    title: 'The Origins',
    image: eh,
    date: '15 - 18 MAY 2019',
    startDate: '2019-05-29T10:05:50.999Z',
    endDate: '2019-05-29T10:05:50.999Z',
    location: 'Berlin',
    country: 'Germany',
    about: about,
    address: address,
  },
  {
    path: '/long-live-asap',
    title: 'Long Live A$AP',
    image: ei,
    date: '15 - 18 MAY 2019',
    startDate: '2019-05-29T10:05:50.999Z',
    endDate: '2019-05-29T10:05:50.999Z',
    location: 'Berlin',
    country: 'Germany',
    about: about,
    address: address,
  },
  {
    path: '/justaxpose-cecilia-moisio',
    title: 'Justaxpose Cecilia Moisio',
    image: ej,
    date: '15 - 18 MAY 2019',
    startDate: '2019-05-29T10:05:50.999Z',
    endDate: '2019-05-29T10:05:50.999Z',
    location: 'Berlin',
    country: 'Germany',
    about: about,
    address: address,
  },
  {
    path: '/nosy-iranja-beach',
    title: 'Nosy Iranja Beach',
    image: ek,
    date: '15 - 18 MAY 2019',
    startDate: '2019-05-29T10:05:50.999Z',
    endDate: '2019-05-29T10:05:50.999Z',
    location: 'Berlin',
    country: 'Germany',
    about: about,
    address: address,
  },
];
