import React from 'react';

import Image from '../Image';
import './ActivityFeed.css';

import { activity } from '../../placeholder';

const ActivityFeed = props => {
  return (
    <div className="activity-feed">
      {props.activity.map((act, i) => {
        return (
          <div
            className="activity-item flex alignCenter justifyBetween"
            key={i}
          >
            <div className="flex alignCenter">
              <Image className="ava" src={act.image} />
              <div className="activity-text">
                <strong>{act.username}</strong> {act.message}{' '}
                {act.event && <strong>{act.event}</strong>}
                {act.venue && <strong>{act.venue}</strong>}
              </div>
            </div>
            <div className="button sm pill">
              <div className="bold">Follow</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

ActivityFeed.defaultProps = {
  activity,
};

export default ActivityFeed;
