function groupByName(arr) {
  const sorted = arr.sort();
  return sorted.reduce((acc, name) => {
    const letter = name[0];
    // acc[letter] = (acc[letter] || []).concat(name);
    if (!acc[letter]) {
      acc[letter] = [name];
    } else {
      acc[letter].push(name);
    }
    return acc;
  }, {});
}

export const bandnames = [
  'Hispanic at the Disco',
  'Nutellica',
  "Jehovah's Mistress",
  'Dewey Decibel System',
  'Gonorrhea With the Wind',
  'Danny Dorito',
  'Panic! At Nabisco',
  'The Yeetles',
  'Yeah, Yeah Nahs',
  'Heavy Flo.',
  'Tax Evader Zim',
  'Karen and the Kids',
  'Gelatin Skeletons',
  'Mark Ruffalo Wild Wings',
  'Deadbeat Deity',
  'Premature Emasculation',
  'Heavy Metal Detectors',
  'Segway to Heaven',
  'Chernobyl Prize',
  'Anti-Vaxxers and the Essential Oils',
  'Resorting to Ramen',
  'Cymbals of oppression',
  'Rocket Seance',
  'Beige Against The Latrine',
  'Caulk Asian',
  'Doggie Doors',
  'In Denial In The Nile',
  'MEGASIN',
  'Teenage Waistline',
  'Black Market Fetus',
  'Weaponized Bodily Fluids',
  'Midnight Breakfast',
  'Shycopath',
  'Skydiver Vomit',
  'Recycled Popular Name From Six Months Ago',
  'Buckingham Phallus',
  'The Rolling Thrones',
  "Charlie Kelly's Angels",
  'Smegma Throne',
  'Cuban Midlife Crisis',
  'Coke nail',
  'Bicurious George',
  'Casual Prolapse',
  'Mary had a little band',
  'A Whole Bunch of Guys Named Chad',
  'Twisted fister',
  'Waiting In The Death Zone',
  'Bloodshovel',
  'Half-eaten ass',
  'Recreational Abortion',
  'Scheduled Failure',
  'Gently With Mayo',
  'Dropkick Schwarzeneggers',
  'Gross Domestic Potluck',
  'Enola Bi',
  'One Prick Tony',
  'Piece Of Shit And The Moderators',
  'The Michael Stripes',
  'Electric assholes',
  'The Blind Venetians',
  'Beytwicé',
  'Albaturkey',
  'Heartbeat Bill',
  'Adjusted For Inflation',
  'Electric High Chair',
  '7th Breakfast',
  'Hymen Explosion',
  'Owner of A Lonely Fart',
  'Mindnumbing Tinnitus',
  'Picnic! At the Disco',
  'Martial Arts and Crafts',
  'Corn on macabre',
  'The Insignificant Others',
  'The Gneiss Guys (incel rock)',
  'MK-Ultron',
  'Crocodildo',
  'Joe Rogan and the Look It Ups',
  'Sled Head Edd and Noggin Toboggan',
  'New Pope Smell',
  'Accidental Mullet',
  'Death Erection',
  'The Slovakian Coleslaw Society',
  'Charles Monroe',
  'Homeschool Shooter',
  'The Bridges I Burnt Will Act Like Candles Guiding Me Back Home',
  'Yes, this is our band name',
  'Conversation! At the Coffee Shop',
  'Midwife Crisis',
  'The Extra Chromosomes',
  'The Remorseless Masturbators',
  'Nordic Crack',
  'Tommy Tendies and the Good Boy Points',
  'Titanic! At The Disco',
  'Shoot the Hostage',
];

export const bands = groupByName(bandnames);
