import React from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/user';

import Image from '../Image';
import { accounts } from '../../placeholder/profile';

export const ProfileFlyout = ({ accounts }) => {
  const user = React.useContext(UserContext);
  return (
    <div className="popup" style={{ width: 240 }}>
      {accounts.map((acc, i) => {
        return (
          <label
            className="flex alignCenter justifyBetween padding2 dim"
            key={i}
          >
            <div className="flex alignCenter">
              <Image className="ava" src={acc.image} />
              <div className="activity-text">
                <strong>{acc.username}</strong>
              </div>
            </div>
            <div className="paddingX2">
              <input type="radio" name="user" value={acc.username} />
            </div>
          </label>
        );
      })}

      <div
        className="flex alignCenter justifyBetween padding2 c-gray dim"
        style={{ pointerEvents: 'none' }}
      >
        <div className="flex alignCenter">
          <Image className="ava" src={''} />
          <div className="activity-text">
            <strong>Create new</strong>
          </div>
        </div>
      </div>

      <div className="flex">
        <Link
          className="flexGrow tac block paddingX2 paddingY4 dim"
          to="/settings"
        >
          Settings
        </Link>
        <div
          className="flexGrow tac block paddingX2 paddingY4 dim cup"
          onClick={user.logout}
        >
          Logout
        </div>
      </div>
    </div>
  );
};

ProfileFlyout.defaultProps = {
  accounts,
};

export default ProfileFlyout;
