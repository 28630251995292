import React from 'react';
import { Link } from 'react-router-dom';
import CityCard from '../components/cards/CityCard';
import Square from '../components/Square';

import { cities } from '../placeholder';

const Cities = props => {
  return (
    <div className="container">
      <div className="section">
        <div className="flex flexWrap columns">
          {props.cities.map((city, i) => (
            <div className="slider-item flexNone column grid4" key={city.path}>
              <Link to={'/city' + city.path}>
                <Square>
                  <CityCard
                    image={city.image}
                    title={city.title}
                    key={city.title}
                  />
                </Square>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Cities.defaultProps = {
  cities,
};

export default Cities;
