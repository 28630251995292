import React from 'react';
import ProfileForm from '../components/forms/ProfileForm';
import SectionLabel from '../components/SectionLabel';

const Settings = props => {
  return (
    <div className="condenced">
      <SectionLabel title="Settings" />
      <ProfileForm />
    </div>
  );
};

export default Settings;
