import React from 'react';
import './Square.css';

const Square = ({ children }) => {
  return (
    <div className="propsquare">
      <div>{children}</div>
    </div>
  );
};

export default Square;
