import rock from '../assets/genres/photo-1547525372-7acfcbcd8acc.jfif';
import jazz from '../assets/genres/photo-1519861410433-7f9e2b469cca.jfif';
import club from '../assets/genres/photo-1532452119098-a3650b3c46d3.jfif';
import pop from '../assets/genres/photo-1497350166004-48ec9adb78bb.jfif';

export const genres = [
  { path: '/dance', title: 'Dance', image: club },
  { path: '/jazz', title: 'Jazz', image: jazz },
  { path: '/rock', title: 'Rock', image: rock },
  { path: '/pop', title: 'Pop', image: pop }
];
