import React from 'react';

import map from '../assets/map.jpg';
import { venues, artists } from '../placeholder';

import { Match } from '../components/helpers/Match';
import Section from '../components/Section';
import SectionLabel from '../components/SectionLabel';
import { ArtsitsGrid } from '../components/sections/ArtsitsGrid';
import StickyFooter from '../components/FixedFooter';
import Schedule from '../components/sections/Schedule';
import Image from '../components/Image';
import { HeadingSection } from '../components/HeadingSection';

const VenuePhotos = ({ venue, height = 400 }) => {
  return (
    <div className="flex">
      <div
        className="image rounded"
        style={{
          height: height,
          width: '100%',
          backgroundImage: `url(${venue.image})`,
        }}
      />
      <div className="condenced flexNone" style={{ margin: '0 20px' }}>
        <div
          className="image rounded"
          style={{
            height: height,
            width: '100%',
            backgroundImage: `url(${venue.image})`,
            // marginLeft: 20,
          }}
        />
      </div>
      <div
        className="image rounded"
        style={{
          height: height,
          width: '100%',
          backgroundImage: `url(${venue.image})`,
        }}
      />
    </div>
  );
};

const VenuePage = ({ match, data: venue, ...props }) => {
  return (
    <div>
      <VenuePhotos venue={venue} />
      <div className="condenced">
        <HeadingSection title={venue.title} description={venue.address} />
        <Section label="About">
          <p className="text">{venue.about}</p>
        </Section>
        <SectionLabel title="Location" />
        <Image src={map} style={{ height: 320 }} />
        <Section label="Upcoming events" path="/events">
          <Schedule />
        </Section>
        <Section label="Hosted Artists" path="/artists">
          <ArtsitsGrid items={props.artists} />
        </Section>
      </div>
      <StickyFooter />
    </div>
  );
};

VenuePage.defaultProps = {
  artists,
};

const Venue = props => {
  return (
    <Match param={'venue'} data={venues} {...props}>
      <VenuePage />
    </Match>
  );
};

export default Venue;
