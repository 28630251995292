import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../context/user';

const PrivateRoute = ({ component, render, ...props }) => {
  const user = useContext(UserContext);
  return (
    <Route
      {...props}
      render={route => {
        if (user.type !== 'guest') {
          if (render) {
            return render(route);
          } else {
            return React.createElement(component, route);
          }
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

export default PrivateRoute;
