import React from 'react';
import { useFormState } from 'react-use-form-state';

import SectionLabel from '../SectionLabel';
import Input from '../inputs/Input';
import TextArea from '../inputs/TextArea';
import Button from '../Button';

import LinksInput from './LinksInput';
import GenresInput from './GenresInput';
import CountrySelect from './CountrySelect';

const ArtistForm = props => {
  const [formState, { text, select, number, checkbox }] = useFormState();

  const handleSubmit = ev => {
    console.log(formState.values);
  };

  return (
    <>
      <SectionLabel title="About" />
      <Input placeholder="Name" {...text('name')} />
      <TextArea placeholder="Description" {...text('description')} />
      <CountrySelect placeholder="Country" {...select('country')} />
      <SectionLabel title="Genres" />
      <GenresInput inputs={{ checkbox }} />
      <SectionLabel title="Members" />
      <div className="flex">
        <Input placeholder="Member" {...text('member')} />
        <Input placeholder="Role" {...text('role')} />
      </div>
      <div className="flex">
        <Input
          placeholder="People Travelling"
          {...number('peopleTravelling')}
        />
        <Input placeholder="People on Stage" {...number('peopleOnStage')} />
      </div>
      <LinksInput inputs={{ text }} />
      <SectionLabel title="Representation" />
      <Input placeholder="Agency" {...text('agency')} />
      <Input placeholder="Record Label" {...text('label')} />
      <Input placeholder="Representative" {...text('representative')} />
      <div className="flex">
        <Input
          placeholder="Representative Email"
          {...text('representativeEmail')}
        />
        <Input
          placeholder="Representative Phone"
          {...text('representativePhone')}
        />
      </div>
      {/* <SectionLabel title="Aspirations" /> */}
      {/* <TextArea placeholder="Career, tour, record, etc plans that you might have." /> */}
      <Button onClick={handleSubmit}>
        <div className="h3">Submit</div>
      </Button>
    </>
  );
};

export default ArtistForm;
