import React from 'react';

import { genres, artists, events, venues } from '../placeholder';
import Section from '../components/Section';
import { FeaturedVenues } from '../components/sections/FeaturedVenues';
import { FeaturedEvents } from '../components/sections/FeaturedEvents';
import { ArtsitsGrid } from '../components/sections/ArtsitsGrid';
import Cover from '../components/Cover';
import GenresSection from '../components/sections/GenresSection';

const defaultProps = {
  artists,
  events: events.slice(2, 6),
  venues,
  genres
};

const Genre = ({ match, ...props }) => {
  const { params } = match;
  const genre = genres.find(genre => genre.path.indexOf(params.genre) !== -1);

  console.log(genre);
  return (
    <div>
      <Cover image={genre.image} title={genre.title} />
      <div className="container">
        <Section label={genre.title + ' Artists'} path={match.url + '/artists'}>
          <ArtsitsGrid items={props.artists} />
        </Section>
        <Section label={genre.title + ' Events'} path={match.url + '/events'}>
          <FeaturedEvents items={props.events} />
        </Section>
        <Section label={genre.title + ' Venues'} path={match.url + '/venues'}>
          <FeaturedVenues items={props.venues} />
        </Section>
        <Section label="Other Genres" path="/genres">
          <GenresSection items={props.genres} prefix="/genre" />
        </Section>
      </div>
    </div>
  );
};

Genre.defaultProps = defaultProps;

export default Genre;
