import React from 'react';

import PopupTrigger from '../PopupTrigger';
import Button from '../Button';
import { MenuItem } from './MenuItem';

import { genres } from '../../lists/genres';

export const SelectGenres = ({ inputs: { selectMultiple, checkbox } }) => {
  return (
    <PopupTrigger
      alignment={0}
      placement={1}
      component={
        <div className="popup" style={{ width: 240, height: 400 }}>
          {genres.map(genre => (
            <MenuItem key={genre} {...checkbox('g', genre.toLowerCase())}>
              {genre}
            </MenuItem>
          ))}
        </div>
      }
    >
      <span>
        <Button>
          <div className="h3">Genre</div>
        </Button>
      </span>
    </PopupTrigger>
  );
};
