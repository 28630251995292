import React from 'react';

import ArtistForm from '../components/forms/ArtistForm';

import { profile } from '../placeholder';
import { ArtistImages } from './Artist';

const ProfileSettings = ({ profile }) => {
  return (
    <div>
      <ArtistImages artist={profile} />
      <div className="condenced">
        <div className="section">
          <ArtistForm />
        </div>
      </div>
    </div>
  );
};

ProfileSettings.defaultProps = {
  profile,
};

export default ProfileSettings;
