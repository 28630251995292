import React from 'react';
import { Route } from 'react-router-dom';

import MessagesList from '../components/lists/MessagesList';
import Chat from '../components/Chat';

import { gigs } from '../placeholder';

const Messages = ({ match, ...props }) => {
  return (
    <div className="condenced">
      <div className="flex" style={{ height: 'calc(100vh - 64px)' }}>
        <div
          className="flexNone overflowAuto"
          style={{
            width: 300,
            borderRight: '1px solid #eee',
          }}
        >
          <MessagesList />
        </div>
        <Route path={`${match.url}/:chat`} component={Chat} />
      </div>
    </div>
  );
};

Messages.defaultProps = {
  chats: gigs,
};

export default Messages;
