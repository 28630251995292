import React from 'react';
import './Chip.css';

const Chip = ({ emoji, label }) => {
  return (
    <div className="chip">
      {emoji && <span className="emoji">{emoji}</span>}
      <span className="medium">{label}</span>
    </div>
  );
};

export default Chip;
