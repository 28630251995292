import React from 'react';
import EventCard from '../cards/EventCard';
import SpringSlider from '../slider/SpringSlider';

export const FeaturedEvents = props => {
  const slides = props.items.map((event, i) => (
    <div className="flexNone column grid4" key={event.title}>
      <EventCard
        path={'/event' + event.path}
        image={event.image}
        title={event.title}
        date={event.date}
        location={event.location}
        key={event.title}
      />
    </div>
  ));
  return (
    // <div className="flex columns">
    <SpringSlider slides={slides} />
    // </div>
  );
};
