import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { UserContext } from '../context/user';

import './Header.css';

import { profile } from '../placeholder';

import Layer from './helpers/Layer';
import Emoji from './helpers/Emoji';
import PopupTrigger from './PopupTrigger';
import ActivityFlyout from './flyouts/ActivityFlyout';
import MessagesFlyout from './flyouts/MessagesFlyout';
import ProfileFlyout from './flyouts/ProfileFlyout';

const links = [
  {
    path: '/artists',
    label: 'Artists',
  },
  {
    path: '/events',
    label: 'Events',
  },
  {
    path: '/venues',
    label: 'Venues',
  },
];

export const Logo = props => {
  return (
    <Link to="/">
      <div className="logo">
        <div
          className="image img"
          style={{
            backgroundImage: `url('https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/198/sign-of-the-horns_1f918.png')`,
            backgroundSize: '50%',
          }}
        />
      </div>
    </Link>
  );
};

const ProfileButton = ({ profile }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      {/* <span className="nav-link bold" style={{ margin: 0 }}>
        Profile{' '}
      </span> */}
      <span
        className="profile-button"
        style={{
          backgroundImage: `url(${profile.image})`,
        }}
      />
    </div>
  );
};

ProfileButton.defaultProps = {
  profile,
};

const UserControls = props => {
  return (
    <div className="flex alignCenter justifyEnd spacing2">
      <Link className="nav-link bold" to="/events">
        Events
      </Link>
      <Link className="nav-link bold" to="/bookmarks">
        Bookmarks
      </Link>
      <PopupTrigger component={<MessagesFlyout height={400} />}>
        <div className="button sm circle flat">
          <Emoji symbol="💬" label="Messages" />
        </div>
      </PopupTrigger>
      <PopupTrigger component={<ActivityFlyout height={400} />}>
        <div className="button sm circle flat">
          <Emoji symbol="🔔" label="Notifications" />
        </div>
      </PopupTrigger>
      <PopupTrigger component={<ProfileFlyout />} hoverable delay={200}>
        <div>
          <Link to="/profile">
            <ProfileButton />
          </Link>
        </div>
      </PopupTrigger>
    </div>
  );
};

const GuestControls = ({ login }) => {
  const [modal, showModal] = React.useState(false);
  return (
    <div className="flex justifyEnd">
      <div
        className="nav-link bold"
        to="/signup"
        onClick={() => showModal(!modal)}
      >
        Sign up
      </div>
      <div
        className="nav-link bold"
        style={{ marginRight: 0 }}
        to="/login"
        onClick={login}
      >
        Log in
      </div>
      {modal && <Layer>Modal</Layer>}
    </div>
  );
};

const Header = props => {
  const user = React.useContext(UserContext);
  return (
    <header className="header">
      <div className="container flex alignCenter justifyBetween equal">
        <div>
          <Logo />
        </div>
        {/* <div className="nav">
          {links.map((link, i) => (
            <NavLink
              className="nav-link bold"
              to={link.path}
              key={link.path + '_' + link.label}
            >
              {link.label}
            </NavLink>
          ))}
        </div> */}
        {user.type === 'guest' ? (
          <GuestControls login={user.login} />
        ) : (
          <UserControls />
        )}
      </div>
    </header>
  );
};

export default Header;
