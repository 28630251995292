import React from 'react';

import PopupTrigger from '../PopupTrigger';
import Button from '../Button';
import { MenuItem } from './MenuItem';

import countries from '../../lists/countries.json';

export const SelectCountries = ({ inputs: { checkbox } }) => {
  return (
    <PopupTrigger
      alignment={0}
      placement={1}
      component={
        <div className="popup" style={{ width: 240, height: 400 }}>
          {countries.map(country => (
            <MenuItem
              key={country.name}
              {...checkbox('cc', country.code.toLowerCase())}
            >
              {country.name}
            </MenuItem>
          ))}
        </div>
      }
    >
      <span>
        <Button>
          <div className="h3">Country</div>
        </Button>
      </span>
    </PopupTrigger>
  );
};
