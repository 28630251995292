import React from 'react';
import { NavLink } from 'react-router-dom';

export const ButtonLink = ({ children, to, ...props }) => {
  return (
    <NavLink
      className="link button pill inline"
      activeClassName="active"
      to={to}
      {...props}
    >
      <div className="h3">{children}</div>
    </NavLink>
  );
};

export default ButtonLink;
