import React from 'react';
import { useFormState } from 'react-use-form-state';

import Input from '../inputs/Input';
import Button from '../Button';

const ProfileForm = props => {
  const [formState, { email, password }] = useFormState();

  const handleSubmit = ev => {
    console.log(formState.values);
  };

  return (
    <>
      <Input placeholder="Email" type="email" {...email('email')} />
      <Input
        placeholder="Old Password"
        type="password"
        {...password('oldPassword')}
      />
      <Input
        placeholder="New Password"
        type="password"
        {...password('newPassword')}
      />
      <Input
        placeholder="Confirm Password"
        type="password"
        {...password('confirmPassword')}
      />
      <Button onClick={handleSubmit}>
        <div className="h3">Submit</div>
      </Button>
    </>
  );
};

export default ProfileForm;
