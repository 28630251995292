import React from 'react';
import { UserContext } from '../context/user';

import Emoji from './helpers/Emoji';

import './BookmarkButton.css';

const BookmarkButton = props => {
  const user = React.useContext(UserContext);
  const handleClick = ev => {
    if (user.type === 'guest') user.login();
    else return false;
  };
  return (
    <div className="icon" onClick={handleClick} {...props}>
      <Emoji symbol="🤘" label="Bookmark" />
    </div>
  );
};

export default BookmarkButton;
