import React, { useState } from 'react';
import './Sandbox.css';
import Button from '../components/Button';
import Modal from '../components/Modal';

import { ProfileFlyout } from '../components/flyouts/ProfileFlyout';
import ActivityFlyout from '../components/flyouts/ActivityFlyout';
import MessagesFlyout from '../components/flyouts/MessagesFlyout';
import Emoji from '../components/helpers/Emoji';
import PopupTrigger from '../components/PopupTrigger';
import SpringSlider from '../components/slider/SpringSlider';

const ModalButton = ({ children }) => {
  const [modal, showModal] = useState(false);

  function handleClick() {
    showModal(!modal);
  }
  function handleDismiss() {
    showModal(false);
  }

  return (
    <>
      <Button onClick={handleClick}>{children}</Button>
      {modal && (
        <Modal onDismiss={handleDismiss}>
          <div>Modal</div>
        </Modal>
      )}
    </>
  );
};

// const usePopup = () => {
//   const [open, isOpen] = useState(false);

//   function handleClick() {
//     isOpen(!open);
//   }
//   function handleDismiss() {
//     isOpen(false);
//   }

//   return { open, handleClick, handleDismiss };
// };

const Sandbox = props => {
  return (
    <div>
      <ModalButton>Open Modal</ModalButton>
      <div className="container flex justifyBetween">
        <div />
        <PopupTrigger component={<ActivityFlyout height={400} />}>
          <div className="button sm circle flat">
            <Emoji symbol="🔔" label="Notifications" />
          </div>
        </PopupTrigger>
        <PopupTrigger component={<MessagesFlyout height={400} />}>
          <div className="button sm circle flat">
            <Emoji symbol="💬" label="Messages" />
          </div>
        </PopupTrigger>

        <PopupTrigger component={<ProfileFlyout />} hoverable>
          <button>Profile</button>
        </PopupTrigger>
      </div>
      <div className="container">
        <SpringSlider
          slides={[
            <div
              className="slider-item flexNone column grid4"
              style={{ height: 300 }}
              key={'a'}
            >
              lani
            </div>,
            <div
              className="slider-item flexNone column grid4"
              style={{ height: 300 }}
              key={'b'}
            >
              lani
            </div>,
            <div
              className="slider-item flexNone column grid4"
              style={{ height: 300 }}
              key={'c'}
            >
              lani
            </div>,
            <div
              className="slider-item flexNone column grid4"
              style={{ height: 300 }}
              key={'d'}
            >
              lani
            </div>,
            <div
              className="slider-item flexNone column grid4"
              style={{ height: 300 }}
              key={'e'}
            >
              lani
            </div>,
            <div
              className="slider-item flexNone column grid4"
              style={{ height: 300 }}
              key={'f'}
            >
              lani
            </div>,
            <div
              className="slider-item flexNone column grid4"
              style={{ height: 300 }}
              key={'g'}
            >
              lani
            </div>,
          ]}
        >
          {/* <div className="slider-item flexNone column grid4">lani</div> */}
        </SpringSlider>
      </div>
    </div>
  );
};

export default Sandbox;
