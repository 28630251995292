import React from 'react';
import { Link } from 'react-router-dom';

import Chip from '../Chip';
import BookmarkButton from '../BookmarkButton';

import genres from '../../lists/genres.json';

const VenueCard = ({ path, image, title, location, tags }) => {
  return (
    <div className="event">
      <div className="relative trigger" style={{ paddingTop: '50%' }}>
        <Link to={path}>
          <div
            className="image"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${image})`,
            }}
          />
        </Link>

        <div className="bookmark">
          <BookmarkButton />
        </div>
      </div>
      <div className="meta bold">{location}</div>
      <Link to={path} className="h3">
        {title}
      </Link>
      <div className="mt4">
        <Chip
          // emoji="🎸"
          // label="Rock"
          {...genres.rock}
        />
        <Chip {...genres.hiphop} />
      </div>
    </div>
  );
};

export default VenueCard;
