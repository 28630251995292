import React from 'react';
import { Link } from 'react-router-dom';
import './Section.css';

const Section = ({ label, description, path, children }) => {
  return (
    <section className="section">
      <div className="mb10">
        <div className="flex alignEnd justifyBetween">
          <h2 className="h2">{label}</h2>
          {path && (
            <Link className="section-link bold" to={path}>
              See all
            </Link>
          )}
        </div>
        {description && <div className="mt2 fz20">{description}</div>}
      </div>
      {children}
    </section>
  );
};

export default Section;
