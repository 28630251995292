import React from 'react';

export const HeadingSection = ({ title, description, children }) => {
  return (
    <div className="section">
      <div className="heading bold">{title}</div>
      {description && <p className="text">{description}</p>}
      {children}
    </div>
  );
};
