import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import './Profile.css';

import { profile, artists, events, venues } from '../placeholder';

import { ArtsitsGrid } from '../components/sections/ArtsitsGrid';
import { EventsGrid } from '../components/sections/EventsGrid';
import { FeaturedVenues } from '../components/sections/FeaturedVenues';
import ActivityFeed from '../components/sections/ActivityFeed';
import Schedule from '../components/sections/Schedule';
import MessagesFeed from '../components/sections/MessagesFeed';
import ButtonLink from '../components/ButtonLink';
import Emoji from '../components/helpers/Emoji';
import Chat from '../components/Chat';

const Profile = ({ match, profile, ...props }) => {
  return (
    <div>
      <div className="condenced">
        <div className="profile">
          <img
            // className="avatar"
            className="cover circle mb6"
            style={{ width: 128, height: 128 }}
            src={profile.image}
            alt={profile.username}
          />
          <h2 className="mb4">
            {profile.username || 'Profile Page'}{' '}
            <Link to={match.url + '/settings'}>
              <Emoji symbol="⚙️" label="Settings" />
            </Link>
          </h2>
        </div>
        <div className="flex justifyBetween" />
      </div>
      <div className="condenced">
        <div style={{ margin: '24px 0 44px' }}>
          <ButtonLink exact to={match.url}>
            Activity
          </ButtonLink>
          {/* <ButtonLink to={match.url + '/messages'}>Messages</ButtonLink> */}
          <ButtonLink to={match.url + '/events'}>Events</ButtonLink>
          <ButtonLink to={match.url + '/following'}>Following</ButtonLink>
          <ButtonLink to={match.url + '/followers'}>Followers</ButtonLink>
          {/* <ButtonLink to={match.url + '/artists'}>Artists</ButtonLink> */}
          {/* <ButtonLink to={match.url + '/events'}>Events</ButtonLink> */}
          {/* <ButtonLink to={match.url + '/venues'}>Venues</ButtonLink> */}
        </div>
        <div className="section">
          <Switch>
            <Route
              path={[match.url + '/artists', match.url + '/followers']}
              render={route => {
                return <ArtsitsGrid items={props.artists} />;
              }}
            />
            <Route
              path={match.url + '/following'}
              render={route => {
                return <EventsGrid items={props.events} />;
              }}
            />
            <Route
              exact
              path={match.url + '/messages/:chat'}
              render={route => {
                return <Chat />;
                // return (
                //   <div className="flex">
                //     <div
                //       className="flexNone"
                //       style={{ width: '20%', overflow: 'hidden' }}
                //     >
                //       <div style={{ width: 500 }}>
                //         <MessagesFeed {...route} />
                //       </div>
                //     </div>
                //     <div>Chat</div>
                //   </div>
                // );
              }}
            />
            <Route
              path={match.url + '/messages'}
              render={route => {
                return <MessagesFeed {...route} />;
              }}
            />
            <Route
              path={match.url + '/venues'}
              render={route => {
                return <FeaturedVenues items={props.venues} />;
              }}
            />
            <Route
              path={[match.url + '/events']}
              render={route => {
                return <Schedule />;
              }}
            />
            <Route
              path={match.url}
              render={route => {
                return <ActivityFeed />;
              }}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

Profile.defaultProps = {
  profile,
  artists,
  events,
  venues,
};

export default Profile;
