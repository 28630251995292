import React from 'react';
import { Link } from 'react-router-dom';

import artist from '../../assets/artists/photo-1521335639660-c569b17006f5.jfif';

const Banner = ({
  image = artist,
  title = 'Become an Artist',
  path = '/become-an-artist',
}) => {
  return (
    <div
      className="banner image"
      style={{ height: 400, backgroundImage: `url(${image})` }}
    >
      <div className="overlay flex alignCenter justifyCenter">
        <div className="container">
          {/* <Button>Become an artist</Button> */}
          <Link to={path}>
            <div className="heading bold" style={{ color: '#fff' }}>
              {title}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
