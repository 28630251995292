export const genres = [
  'Afro',
  'Avant-garde',
  'Blues',
  'Caribbean',
  'Comedy',
  'Country',
  'Easy listening',
  'Electronic',
  'Folk',
  'Hip hop',
  'Jazz',
  'Latin',
  'Pop',
  'R&B and soul',
  'Rock',
];
