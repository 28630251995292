import React from 'react';
import { useFormState } from 'react-use-form-state';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import Button from '../Button';

import { SelectGenres } from './SelectGenres';
import { SelectCountries } from './SelectCountries';

const Filter = ({ params, filter, location, history }) => {
  const initialFilters = location.search
    ? qs.parse(location.search)
    : { genre: [], country: [] };
  const [formState, { checkbox }] = useFormState(initialFilters);

  React.useEffect(() => {
    // console.log(formState.values);
    history.push({
      search: '?' + qs.stringify(formState.values),
    });
  }, [formState.values]);

  // if (location.search) {
  //   console.log(qs.parse(location.search));
  // }
  return (
    <div className="spacing6">
      <Button>
        <div className="h3">Date</div>
      </Button>
      <SelectGenres inputs={{ checkbox }} />
      <SelectCountries inputs={{ checkbox }} />
    </div>
  );
};

export default withRouter(Filter);
