import React from 'react';

import { venues } from '../placeholder';
import { FeaturedVenues } from '../components/sections/FeaturedVenues';
import Filter from '../components/filters/Filter';
import Banner from '../components/banners/Banner';

import banner from '../assets/banners/photo-1516137235045-2937f026afed.jfif';

const Venues = props => {
  return (
    <div>
      <div className="container">
        <div style={{ margin: '24px 0 44px' }}>
          <Filter params={['Date', 'Genre', 'Country']} />
        </div>

        <div className="section">
          <FeaturedVenues items={props.venues} />
        </div>
      </div>
      <Banner image={banner} title="Host an Event" path="/host-event" />
    </div>
  );
};

Venues.defaultProps = {
  venues,
};

export default Venues;
