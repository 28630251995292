import React from 'react';
import { Link } from 'react-router-dom';

import Image from '../Image';

import { gigs } from '../../placeholder';

const MessagesFeed = ({ match, ...props }) => {
  return (
    <div>
      {props.gigs.map((gig, i) => {
        return (
          <div
            className="activity-item flex alignCenter justifyBetween"
            key={i}
          >
            <div className="flex alignCenter">
              <Image className="ava flexNone" src={gig.image} />
              <Link
                to={
                  match.url + `/${gig.username.replace(' ', '_').toLowerCase()}`
                }
              >
                <div className="activity-text">
                  <div>
                    <strong>{gig.username}</strong>
                  </div>
                  {gig.message}
                  {/* {gig.event && <strong>{gig.event}</strong>} */}
                  {/* {gig.venue && <strong>{gig.venue}</strong>} */}
                </div>
              </Link>
            </div>
            <div>
              <div>{gig.date}</div>
              <div>{gig.time}</div>
            </div>
            <div style={{ width: 100, textAlign: 'left' }}>EUR {gig.offer}</div>
            <div className="flexNone">
              <div className="button sm pill">
                <div className="bold">Accept</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

MessagesFeed.defaultProps = {
  gigs,
};

export default MessagesFeed;
