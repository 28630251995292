// import a from '../assets/artists/tumblr_mxph7f4OkD1qaqv2ro1_1280.jpg';
// import b from '../assets/artists/2c06fc609baa00f3c95d5de223518eef.jpg';
// import c from '../assets/artists/8283027590_c97def08fc_o.jpg';
// import d from '../assets/artists/tumblr_mcynvsbiNe1r9ouy2o1_500.jpg';
// import e from '../assets/artists/11209666504_686f3dc6cb_b.jpg';
// import f from '../assets/artists/pauldamato.jpg';
// import g from '../assets/artists/tumblr_nly76eSHy11qmvhifo1_1280.jpg';
// import h from '../assets/artists/tumblr_m7ooortuye1qcuv78o1_1280.jpg';

import { photos } from './photos';

const a = photos[0].urls.small;
const b = photos[1].urls.small;
const c = photos[2].urls.small;
const d = photos[3].urls.small;
const e = photos[4].urls.small;
const f = photos[5].urls.small;
const g = photos[6].urls.small;
const h = photos[7].urls.small;

const about = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, vitae odio maiores exercitationem earum nesciunt impedit eos excepturi sit facilis ipsa fugiat dolore cupiditate reiciendis consectetur, odit quia. Facere culpa, inventore, iste laudantium perferendis a dolores itaque quas fugit doloremque pariatur tenetur mollitia? Unde a aperiam dolores culpa ipsam dolore nesciunt maiores, libero dolorum, iusto repudiandae explicabo vitae reprehenderit debitis reiciendis velit voluptatum? Error sed nobis, facere fuga totam officia ea doloribus reiciendis, soluta qui illo reprehenderit impedit culpa, eius recusandae eaque neque inventore ex sapiente dolor minus est quae vero ab? Aperiam dolor cupiditate perspiciatis nostrum fuga dolores! Ducimus?`;
const links = [
  {
    url: 'http://spotify.com',
    label: 'Spotify',
  },
  {
    url: 'http://instagram.com',
    label: 'Instagram',
  },
  {
    url: 'http://website.com',
    label: 'Website',
  },
];

const lang = ['english', 'russian'];
const members = ['Laima', 'Elisabeth', 'Darla'];

export const artists = [
  {
    path: '/natalie-ueland',
    title: 'Natalie Ueland',
    image: a,
    country: 'ES',
    city: 'Barcelona',
    tags: ['pop'],
    language: ['en'],
    about,
    links,
    lang,
    members,
  },
  {
    path: '/walther-wohrle',
    title: 'Walther Wöhrle',
    image: b,
    country: 'DE',
    city: 'Berlin',
    tags: ['rock', 'jazz'],
    language: ['en'],
    about,
    links,
    lang,
    members,
  },
  {
    path: '/veera-kalm',
    title: 'Veera Kalm',
    image: c,
    country: 'EE',
    city: 'Tallinn',
    tags: ['electronic'],
    language: ['en'],
    about,
    links,
    lang,
    members,
  },
  {
    path: '/isobel-lane',
    title: 'Isobel Lane',
    image: d,
    country: 'PT',
    city: 'Porto',
    tags: ['pop', 'latin'],
    language: ['en'],
    about,
    links,
    lang,
    members,
  },
  {
    path: '/nathan-walker',
    title: 'Nathan Walker',
    image: e,
    country: 'UK',
    city: 'London',
    tags: ['hiphop'],
    language: ['en'],
    about,
    links,
    lang,
    members,
  },
  {
    path: '/brittany-wade',
    title: 'Brittany Wade',
    image: f,
    country: 'US',
    city: 'Los Angeles',
    tags: ['soul'],
    language: ['en'],
    about,
    links,
    lang,
    members,
  },
  {
    path: '/holly-brown',
    title: 'Holly Brown',
    image: g,
    country: 'US',
    city: 'Chicago',
    tags: ['blues', 'jazz'],
    language: ['en'],
    about,
    links,
    lang,
    members,
  },
  {
    path: '/theo-tucker',
    title: 'Theo Tucker',
    image: h,
    country: 'UK',
    city: 'London',
    tags: ['hiphop'],
    language: ['en'],
    about,
    links,
    lang,
    members,
  },
];
