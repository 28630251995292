import React from 'react';

const ArtistsIndex = ({ artists }) => {
  return (
    <div className="flex flexWrap">
      {Object.keys(artists).map(letter => {
        const group = artists[letter];
        return (
          <div className="flexNone grid4 paddingY4" key={letter}>
            <div className="marginY2">
              <div className="h3">{letter}</div>
            </div>
            <div style={{ lineHeight: '24px' }}>
              {group.map(artist => {
                return <div key={artist}>{artist}</div>;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ArtistsIndex;
