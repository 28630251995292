import React from 'react';

export const YoutubeVideo = ({ src, style, iframeStyle, ...props }) => {
  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: '56.25%',
        // paddingTop: 30,
        height: 0,
        overflow: 'hidden',
        ...style,
      }}
    >
      <iframe
        title="Youtube video"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          ...iframeStyle,
        }}
        {...props}
      />
    </div>
  );
};
