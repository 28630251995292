import React from 'react';
import './Schedule.css';

import { schedule } from '../../placeholder';
import CalendarDay from '../CalendarDay';

const Schedule = ({ schedule }) => {
  return (
    <div className="schedule">
      <div className="table">
        <div className="table__row line bold">
          <div className="table__cell">Date</div>
          <div className="table__cell">Event</div>
          <div className="table__cell">Location</div>
          <div className="table__cell">Link</div>
        </div>
        {schedule.map((event, i) => {
          return (
            <div className="table__row line" key={i}>
              <div className="table__cell padding0">
                {/* {event.date.substring(8, 10) + '/' + event.date.substring(5, 7)} */}
                <CalendarDay date={event.date} />
              </div>
              <div className="table__cell">{event.name}</div>
              <div className="table__cell">{event.location}</div>
              <div className="table__cell">Link</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Schedule.defaultProps = {
  schedule,
};

export default Schedule;
