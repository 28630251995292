import { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

const Layer = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  const portal = useRef(null);

  useEffect(() => {
    if (
      typeof document !== 'undefined' &&
      document.createElement &&
      document.body
    ) {
      portal.current = document.createElement('div');
      document.body.appendChild(portal.current);
      setMounted(true);
    }
    return () => {
      if (document.body) {
        document.body.removeChild(portal.current);
      }
    };
  }, []);

  return mounted && createPortal(children, portal.current);
};

export default Layer;
