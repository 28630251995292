import React from 'react';

import { cities, artists, events, venues, genres } from '../placeholder';
import Section from '../components/Section';
import { FeaturedVenues } from '../components/sections/FeaturedVenues';
import { FeaturedEvents } from '../components/sections/FeaturedEvents';
import { ArtsitsGrid } from '../components/sections/ArtsitsGrid';
import Cover from '../components/Cover';
import GenresSection from '../components/sections/GenresSection';

const defaultProps = {
  artists,
  events: events.slice(4, 8),
  venues,
  genres,
};

const City = ({ match, ...props }) => {
  const { params } = match;
  const city = cities.find(city => city.path.indexOf(params.id) !== -1);

  console.log(city);
  return (
    <div>
      <Cover image={city.image} title={city.title} />
      <div className="container">
        <Section label="Popular Artists" path={match.url + '/artists'}>
          <ArtsitsGrid items={props.artists} />
        </Section>
        <Section label="Upcoming Events" path={match.url + '/events'}>
          <FeaturedEvents items={props.events} />
        </Section>
        <Section label="Popular Venues" path={match.url + '/venues'}>
          <FeaturedVenues items={props.venues} />
        </Section>
        <Section label="Popular Genres" path={match.url + '/genres'}>
          <GenresSection items={props.genres} />
        </Section>
      </div>
    </div>
  );
};

City.defaultProps = defaultProps;

export default City;
