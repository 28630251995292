import Tallinn from '../assets/cities/photo-1547918641-759efa55629a.jfif';
import Berlin from '../assets/cities/photo-1551354099-53143ba45404.jfif';
import Seoul from '../assets/cities/photo-1555162928-38aca2d0be51.jfif';
import Piter from '../assets/cities/photo-1555460285-763ba96917d2.jfif';

export const cities = [
  {
    path: '/berlin',
    title: 'Berlin',
    image: Berlin,
    totalEvents: 21,
    totalArtists: 114,
  },
  {
    path: '/tallinn',
    title: 'Tallinn',
    image: Tallinn,
    totalEvents: 7,
    totalArtists: 21,
  },
  {
    path: '/st-petersburg',
    title: 'St. Petersburg',
    image: Piter,
    totalEvents: 14,
    totalArtists: 72,
  },
  {
    path: '/seoul',
    title: 'Seoul',
    image: Seoul,
    totalEvents: 14,
    totalArtists: 21,
  },
];
