import React from 'react';

import { cities, artists, events, venues, genres } from '../placeholder';
import banner from '../assets/artists/photo-1521335639660-c569b17006f5.jfif';

import Section from '../components/Section';
import { FeaturedCities } from '../components/sections/FeaturedCities';
import { FeaturedArtists } from '../components/sections/FeaturedArtists';
import { FeaturedEvents } from '../components/sections/FeaturedEvents';
import { FeaturedVenues } from '../components/sections/FeaturedVenues';
import GenresSection from '../components/sections/GenresSection';
import Banner from '../components/banners/Banner';

// import { VideoCover } from '../components/VideoCover';
// import poster from '../assets/cover.jpg';
// import cover from '../assets/cover.mp4';

const defaultProps = {
  cities,
  artists: artists.slice(0, 6),
  events,
  venues,
  genres,
};

const FrontPage = props => {
  return (
    <div>
      {/* <VideoCover src={cover} poster={poster} /> */}
      <div className="container">
        <Section label="Cities" path="/discover/cities">
          <FeaturedCities items={props.cities} />
        </Section>
        <Section label="Featured Artists" path="/discover/artists">
          <FeaturedArtists items={props.artists} cols={6} />
        </Section>
        <Section
          label="Featured Events"
          description="A careful selection of creative events, exhibitions and interesting meetups"
          path="/discover/events"
        >
          <FeaturedEvents items={props.events} />
        </Section>
        <Section label="Featured Venues" path="/discover/venues">
          <FeaturedVenues items={props.venues} />
        </Section>
        <Section label="Genres" path="/discover/genres">
          <GenresSection items={props.genres} prefix="/discover/genre" />
        </Section>
        <Section
          label="Events Nearby"
          description="A careful selection of creative events, exhibitions and interesting meetups"
          path="/discover/events"
        >
          <FeaturedEvents items={[...props.events].reverse()} />
        </Section>
        <Section label="Venues Nearby" path="/discover/venues">
          <FeaturedVenues items={[...props.venues].reverse()} />
        </Section>
        <Section label="Popular Artists" path="/discover/artists">
          <FeaturedArtists items={[...props.artists].reverse()} cols={6} />
        </Section>
      </div>
      <Banner image={banner} title="Become an Artist" />
    </div>
  );
};

FrontPage.defaultProps = defaultProps;

export default FrontPage;
