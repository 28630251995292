import React from 'react';
import { useFormState } from 'react-use-form-state';

import SectionLabel from '../SectionLabel';
import Input from '../inputs/Input';
import TextArea from '../inputs/TextArea';
import Select from '../inputs/Select';
import Button from '../Button';

import LinksInput from './LinksInput';
import GenresInput from './GenresInput';
import CountrySelect from './CountrySelect';

const EventForm = props => {
  const [formState, { text, select, checkbox }] = useFormState();

  const handleSubmit = ev => {
    console.log(formState.values);
  };

  return (
    <>
      <SectionLabel title="About" />
      <Input placeholder="Event Name" {...text('title')} />
      <CountrySelect placeholder="Country" {...select('country')} />
      <Input placeholder="Location" {...text('location')} />
      <TextArea placeholder="Description" {...text('description')} />
      <div className="flex">
        <Select placeholder="Start Date" {...select('startDate')} />
        <Select placeholder="End Date" {...select('endDate')} />
      </div>

      <SectionLabel title="Genres" />
      <GenresInput inputs={{ checkbox }} />

      <LinksInput inputs={{ text }} />
      <Button onClick={handleSubmit}>
        <div className="h3">Submit</div>
      </Button>
    </>
  );
};

export default EventForm;
