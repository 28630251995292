import ee from '../assets/events/01_moriyama-cubic-prism.jpg';
import ef from '../assets/events/1670889-slide-dsc-8771.jpg';
import eg from '../assets/events/1M8E7497-Edit_0.jpg';

const about = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, vitae odio maiores exercitationem earum nesciunt impedit eos excepturi sit facilis ipsa fugiat dolore cupiditate reiciendis consectetur, odit quia. Facere culpa, inventore, iste laudantium perferendis a dolores itaque quas fugit doloremque pariatur tenetur mollitia? Unde a aperiam dolores culpa ipsam dolore nesciunt maiores, libero dolorum, iusto repudiandae explicabo vitae reprehenderit debitis reiciendis velit voluptatum? Error sed nobis, facere fuga totam officia ea doloribus reiciendis, soluta qui illo reprehenderit impedit culpa, eius recusandae eaque neque inventore ex sapiente dolor minus est quae vero ab? Aperiam dolor cupiditate perspiciatis nostrum fuga dolores! Ducimus?`;

const address = 'Schwedter Str. 17, 10119 Berlin, Germany';

export const venues = [
  {
    path: '/open-air',
    title: 'Open Air',
    image: ee,
    date: '15 - 18 MAY 2019',
    location: 'Berlin',
    about: about,
    address: address,
  },
  {
    path: '/truck-park',
    title: 'Truck Park',
    image: ef,
    date: '15 - 18 MAY 2019',
    location: 'Berlin',
    about: about,
    address: address,
  },
  {
    path: '/lovebox-city',
    title: 'Lovebox City',
    image: eg,
    date: '15 - 18 MAY 2019',
    location: 'Berlin',
    about: about,
    address: address,
  },
];
