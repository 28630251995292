import { photos } from './photos';

const FOLLOWING = 'is now following you';
const PERFORMING = 'will be performing at';
const PERFORM = 'will perfrom at';
const LOOKINGFOR = 'is looking for artists';
const INVITING = 'invited you to perform at';

export const activity = [
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/198/cat-face_1f431.png',
    image: photos[10].urls.thumb,
    username: 'Yellow Cat',
    message: INVITING,
    event: 'Event',
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/fox-face_1f98a.png',
    image: photos[11].urls.thumb,
    username: 'Orange Fox',
    message: FOLLOWING,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/panda-face_1f43c.png',
    image: photos[12].urls.thumb,
    username: 'Panda Face',
    message: FOLLOWING,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/frog-face_1f438.png',
    image: photos[13].urls.thumb,
    username: 'Zabka',
    message: FOLLOWING,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/rabbit-face_1f430.png',
    image: photos[14].urls.thumb,
    username: 'Rabbit',
    message: FOLLOWING,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/198/cat-face_1f431.png',
    image: photos[15].urls.thumb,
    username: 'Yellow Cat',
    message: PERFORMING,
    event: 'Event',
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/fox-face_1f98a.png',
    image: photos[16].urls.thumb,
    username: 'Orange Fox',
    message: FOLLOWING,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/panda-face_1f43c.png',
    image: photos[17].urls.thumb,
    username: 'Panda Face',
    message: PERFORM,
    venue: 'Venue',
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/frog-face_1f438.png',
    image: photos[18].urls.thumb,
    username: 'Zabka',
    message: FOLLOWING,
  },
  {
    // image: 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/rabbit-face_1f430.png',
    image: photos[19].urls.thumb,
    username: 'Rabbit',
    message: LOOKINGFOR,
  },
];
