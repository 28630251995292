import React from 'react';
// import { UserContext } from '../context/user';
import './Chat.css';

import { profile } from '../placeholder';

import Image from './Image';

// const ChatPlaceholder = props => {
//   return (
//     <div className="flex alignCenter justifyCenter" style={{ flex: 1 }}>
//       ¯\_(ツ)_/¯
//     </div>
//   );
// };

const ChatMessage = ({ author, text, time }) => {
  return (
    <div className="flex marginY4 paddingX4">
      <Image className="chat-avatar" src={author.image} />
      <div className="flexGrow">
        <div className="bold">{author.username}</div>
        <div className="marginY2" style={{ whiteSpace: 'pre-wrap' }}>
          {text}
        </div>
      </div>
      <div className="fz12 c-gray">{time}</div>
    </div>
  );
};

const Chat = ({ match, profile }) => {
  const [messages, updateMessages] = React.useState([]);

  const onKeyPress = ev => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault();
      const message = ev.target.value;
      ev.target.value = '';
      updateMessages([...messages, message]);
    }
  };
  // const user = React.useContext(UserContext);
  return (
    <div className="flex flexColumn">
      {/* <ChatPlaceholder /> */}
      <div
        className="flex overflowAuto"
        style={{
          height: '100%',
          flexDirection: 'column-reverse',
        }}
      >
        <div>
          <ChatMessage
            author={profile}
            text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus ad,
      labore architecto atque unde amet!"
            time="18:59:05"
          />
          <ChatMessage
            author={{ username: match.params.chat, image: null }}
            text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores sit ratione dolores veritatis saepe laudantium culpa neque dicta quas sequi?"
            time="19:00:05"
          />
          {messages.map(message => (
            <ChatMessage
              author={profile}
              text={message}
              time="19:00:05"
              key={message}
            />
          ))}
        </div>
      </div>
      <div className="chat-footer flex alignCenter paddingX4">
        <Image className="chat-avatar" src={profile.image} />
        <textarea
          className="chat-input"
          placeholder="Type a message"
          rows={1}
          onKeyPress={onKeyPress}
        />
        <div className="button sm pill flexNone ms4">Send</div>
      </div>
    </div>
  );
};

Chat.defaultProps = {
  profile,
};

export default Chat;
