import React, { useRef, useState } from 'react';
import Popup from './Popup';

const PopupTrigger = ({
  children,
  component,
  alignment = 2,
  placement = 1,
  hoverable,
  delay = 400,
}) => {
  const anchorRef = useRef(null);
  const [open, isOpen] = useState(false);

  function toggleModal() {
    isOpen(!open);
  }

  function closeModal() {
    isOpen(false);
  }

  const timer = useRef(null);

  const onMouseEnter = ev => {
    // console.log('onMouseEnter');
    if (timer.current) {
      clearTimeout(timer.current);
    }
    isOpen(true);
  };

  const onMouseLeave = ev => {
    // console.log('onMouseLeave');
    timer.current = setTimeout(() => {
      closeModal();
    }, delay);
  };

  const trigger = hoverable
    ? {
        onMouseEnter: onMouseEnter,
        onMouseLeave: onMouseLeave,
      }
    : { onClick: toggleModal };

  return (
    <>
      {React.cloneElement(children, { ...trigger, ref: anchorRef })}
      {open && (
        <Popup
          anchor={anchorRef.current}
          alignment={alignment}
          placement={placement}
          onDismiss={closeModal}
          hoverable={
            hoverable
              ? {
                  onMouseEnter: onMouseEnter,
                  onMouseLeave: onMouseLeave,
                }
              : undefined
          }
          // shouldFocus={false}
        >
          {component}
        </Popup>
      )}
    </>
  );
};

export default PopupTrigger;
