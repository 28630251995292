import React from 'react';
import cc from 'classcat';

import './Button.css';

const Button = ({ text, size, block, className, children, ...props }) => {
  const button = cc([
    'button',
    block ? 'block' : 'inline',
    {
      sm: size === 'small',
      h4: size !== 'small',
      h5: size === 'small',
    },
    className,
  ]);
  return (
    <div className={button} {...props}>
      {text || children}
    </div>
  );
};

export default Button;
