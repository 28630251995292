import React from 'react';

const Image = ({ src, style, className, children }) => {
  const cls = ['image', className].join(' ').trim();
  return (
    <div className={cls} style={{ backgroundImage: `url(${src})`, ...style }}>
      {children}
    </div>
  );
};

export default Image;
