import React from 'react';

export const MenuItem = ({ label, style, children, ...props }) => {
  return (
    <label className="menu-item dim flex alignCenter paddingX4" style={style}>
      <input type="checkbox" {...props} />
      <span className="medium marginX4">{label || children}</span>
    </label>
  );
};
