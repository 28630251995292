import React from 'react';

import { artists, bands } from '../placeholder';
import { ArtsitsGrid } from '../components/sections/ArtsitsGrid';
import ArtistsIndex from '../components/sections/ArtistsIndex';
import Banner from '../components/banners/Banner';
import Filter from '../components/filters/Filter';
import SectionLabel from '../components/SectionLabel';

const Artists = props => {
  return (
    <div>
      <div className="container">
        <div style={{ margin: '24px 0 44px' }}>
          <Filter params={['Date', 'Genre', 'Country']} />
        </div>

        <div className="section">
          <ArtsitsGrid items={props.artists} />
        </div>
        <div className="section">
          <SectionLabel title="Index" />
          <ArtistsIndex artists={bands} />
        </div>
      </div>
      <Banner />
    </div>
  );
};

Artists.defaultProps = {
  artists,
  bands,
};

export default Artists;
