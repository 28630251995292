import React from 'react';
import { useFormState } from 'react-use-form-state';

import SectionLabel from '../SectionLabel';
import Input from '../inputs/Input';
import TextArea from '../inputs/TextArea';
import Button from '../Button';

import LinksInput from './LinksInput';
import GenresInput from './GenresInput';
import CountrySelect from './CountrySelect';

const VenueForm = props => {
  const [formState, { text, select, checkbox }] = useFormState();

  const handleSubmit = ev => {
    console.log(formState.values);
  };
  return (
    <>
      <SectionLabel title="About" />
      <Input placeholder="Venue Name" {...text('title')} />
      <CountrySelect placeholder="Country" {...select('country')} />
      <Input placeholder="Location" {...text('location')} />
      <TextArea placeholder="Description" {...text('description')} />
      <LinksInput inputs={{ text }} />
      <SectionLabel title="Preferences" />
      <GenresInput inputs={{ checkbox }} />

      <Button onClick={handleSubmit}>
        <div className="h3">Submit</div>
      </Button>
    </>
  );
};

export default VenueForm;
