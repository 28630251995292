import React, { useEffect, useState, createContext } from 'react';

export const UserContext = createContext('guest');

const types = {
  LOGIN: 'USER_LOGIN',
  LOGOUT: 'USER_LOGOUT',
  LOADING: 'USER_LOADING',
  ERROR: 'USER_ERROR',
};

const initialState = {
  authenticated: false,
  token: null,
  error: null,
  loading: false,
};

const actions = {
  login: token => ({ type: types.LOGIN, payload: token }),
  logout: () => ({ type: types.LOGOUT }),
  loading: () => ({ type: types.LOADING }),
  error: err => ({ type: types.ERROR, payload: err }),
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        authenticated: true,
        token: action.payload.token,
        error: null,
        loading: false,
      };
    case types.LOGOUT:
      return initialState;
    case types.LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const prevAuthBody = window.localStorage.getItem('authBody') || 'guest';
  const [user, setUser] = useState(prevAuthBody);

  useEffect(() => {
    window.localStorage.setItem('authBody', user);
  }, [user]);

  const login = ev => {
    const value = window.prompt('Log in');
    if (value) {
      setUser('user');
    }
  };

  const logout = ev => setUser('guest');

  useEffect(() => {
    window.dev = { login, logout };
  }, []);

  return (
    <UserContext.Provider
      value={{ type: user, login, logout, state, dispatch, actions }}
    >
      {children}
    </UserContext.Provider>
  );
};
