import React from 'react';
import { withRouter } from 'react-router-dom';

import { gigs } from '../../placeholder';
import MessagesList from '../lists/MessagesList';

const MessagesFlyout = ({ items, height, width, match }) => {
  return (
    <div className="popup" style={{ height, width }}>
      <MessagesList items={items} />
    </div>
  );
};

MessagesFlyout.defaultProps = {
  items: gigs,
};

export default withRouter(MessagesFlyout);
