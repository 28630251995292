import React from 'react';

import { events, artists } from '../placeholder';
import map from '../assets/map.jpg';

import { Match } from '../components/helpers/Match';
import Section from '../components/Section';
import SectionLabel from '../components/SectionLabel';
import StickyFooter from '../components/FixedFooter';
import Image from '../components/Image';
import { ArtsitsGrid } from '../components/sections/ArtsitsGrid';
import { HeadingSection } from '../components/HeadingSection';

const EventImages = ({ event }) => {
  return (
    <div className="flex">
      <div
        className="image rounded"
        style={{
          height: 440,
          width: '100%',
          backgroundImage: `url(${event.image})`,
        }}
      />
      <div className="condenced flex flexNone" style={{ margin: '0 20px' }}>
        <div
          className="image rounded flexNone"
          style={{
            height: 440,
            width: 256,
            backgroundImage: `url(${event.image})`,
          }}
        />
        <div
          className="image rounded"
          style={{
            height: 440,
            width: '100%',
            backgroundImage: `url(${event.image})`,
            marginLeft: 20,
          }}
        />
      </div>
      <div
        className="image rounded"
        style={{
          height: 440,
          width: '100%',
          backgroundImage: `url(${event.image})`,
        }}
      />
    </div>
  );
};

const EventPage = ({ match, data: event, ...props }) => {
  return (
    <div>
      <EventImages event={event} />
      <div className="condenced">
        <HeadingSection
          title={event.title}
          description={event.location + ', ' + event.date}
        />
        <Section label="About">
          <p className="text">{event.about}</p>
        </Section>
        <SectionLabel title="Location" />
        <Image src={map} style={{ height: 320 }} />
        <Section label="Line up" path={match.url + '/artists'}>
          <ArtsitsGrid items={props.artists} />
        </Section>
      </div>
      <StickyFooter actions={['Apply', 'Follow']} />
    </div>
  );
};

EventPage.defaultProps = {
  artists,
};

const Event = props => {
  return (
    <Match param={'event'} data={events} {...props}>
      <EventPage />
    </Match>
  );
};

export default Event;
