import React, { useRef } from 'react';
import { useClickOutside } from '../hooks/useClickOutside';
import Layer from './helpers/Layer';

function getPlacement(placement) {
  let position;
  switch (+placement) {
    case 0:
      position = 'bottom';
      break;
    case 1:
      position = 'top';
      break;
    case 2:
      position = 'right';
      break;
    case 3:
      position = 'left';
      break;
    default:
      position = 'top';
  }
  return { [position]: '100%' };
}

function alignItems(placement, alignment) {
  if (+placement === 2) {
    return 'flex-end';
  } else if (+placement === 3) {
    return 'flex-start';
  } else {
    switch (+alignment) {
      case 0:
        return 'flex-start';
      case 1:
        return 'center';
      case 2:
        return 'flex-end';
      default:
        return 'center';
    }
  }
}

function justifyContent(placement, alignment) {
  if (+placement > 1) {
    switch (+alignment) {
      case 0:
        return 'flex-end';
      case 1:
        return 'center';
      case 2:
        return 'flex-start';
      default:
        return 'center';
    }
  } else {
    switch (+placement) {
      case 0:
        return 'flex-end';
      case 1:
        return 'flex-start';
      case 2:
        return 'center';
      case 3:
        return 'center';
      default:
        return 'center';
    }
  }
}

const Popup = ({
  anchor,
  placement = 1,
  alignment = 1,
  children,
  shouldFocus,
  onDismiss,
  hoverable,
}) => {
  const rect = anchor.getBoundingClientRect();
  const ref = useRef(null);
  useClickOutside(ref, onDismiss);

  const ghost = {
    position: 'fixed',
    zIndex: 999999999,

    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,

    pointerEvents: 'none',
  };

  const align = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99999,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    justifyContent: justifyContent(placement, alignment),
    alignItems: alignItems(placement, alignment),
  };

  const place = {
    position: 'relative',
    // margin: 8,
    ...getPlacement(placement),

    pointerEvents: 'auto',
    // width: '100%', // alignment 3 = container width
  };

  return (
    <Layer>
      <div
        style={ghost}
        onMouseEnter={hoverable ? hoverable.onMouseEnter : undefined}
        onMouseLeave={hoverable ? hoverable.onMouseLeave : undefined}
      >
        <div style={align}>
          <div style={place} ref={ref}>
            {children}
          </div>
        </div>
      </div>
    </Layer>
  );
};

export default Popup;
