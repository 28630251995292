import React from 'react';
import VenueCard from '../cards/VenueCard';

export const FeaturedVenues = props => {
  return (
    <div className="flex columns">
      {props.items.map((event, i) => (
        <div className="flexNone column grid3" key={event.title}>
          <VenueCard
            height={300}
            path={'/venue' + event.path}
            image={event.image}
            title={event.title}
            location={event.address}
            key={event.title}
          />
        </div>
      ))}
    </div>
  );
};
