import React from 'react';

import Chip from '../Chip';

import './CityCard.css';

const CityCard = ({ image, title, totalEvents, totalArtists }) => {
  return (
    <div
      className="city image rounded"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="overlay flex alignCenter justifyCenter">
        <div>
          <div className="h2">{title}</div>
          <div
            className="absolute flex justifyBetween padding2"
            style={{ bottom: 0, left: 0, right: 0 }}
          >
            {totalEvents && <Chip emoji="🎉" label={`${totalEvents}`} />}
            {totalArtists && <Chip emoji="🤘" label={`${totalArtists}`} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityCard;
