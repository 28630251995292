import React from 'react';

import Chip from './Chip';
import genres from '../lists/genres.json';

const Cover = ({ image, title, tags }) => {
  return (
    <div
      className="image"
      style={{ height: 400, backgroundImage: `url(${image})` }}
    >
      <div className="overlay flex alignCenter justifyCenter">
        <div className="condenced">
          <div className="heading bold">{title}</div>

          {tags && (
            <div className="tags">
              {tags.map(tag => (
                <Chip {...genres[tag]} key={tag} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Cover.defaultProps = {
  tags: [
    'afro',
    'avantgarde',
    'blues',
    'caribbean',
    'comedy',
    'country',
    'easy',
    'electronic',
    'folk',
    'hiphop',
    'jazz',
    'latin',
    'pop',
    'soul',
    'rock',
  ],
};

export default Cover;
