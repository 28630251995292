import React from 'react';
import { UserContext } from '../context/user';
import './FixedFooter.css';

import Select from './inputs/Select';
import Button from './Button';
// import Button from './Button';

// const Message = props => {
//   return (
//     <div className="send-message">
//       <input type="text" />
//       <div className="button sm pill">Send</div>
//     </div>
//   );
// };

const Invite = props => {
  return (
    <div className="flex">
      <Select placeholder="Event name" />
      <div
        className="button sm pill flexNone"
        // onClick={handleClick}
        // key={action}
      >
        <div className="bold">Invite</div>
      </div>
    </div>
  );
};

const StickyFooter = ({ actions = ['Message', 'Follow'] }) => {
  const user = React.useContext(UserContext);
  const handleClick = ev => {
    if (user.type === 'guest') user.login();
    else return false;
  };
  return (
    <div className="sticky">
      <div className="condenced">
        <div className="flex justifyBetween">
          {/* <Invite /> */}
          {actions.map(action => {
            return (
              <Button
                onClick={handleClick}
                text={action}
                size="small"
                key={action}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StickyFooter;
