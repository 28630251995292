export const schedule = [
  {
    image:
      'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/198/cat-face_1f431.png',
    name: 'Yellow Cat',
    location: 'Berlin, Germany',
    date: '2019-05-29T10:05:50.999Z',
  },
  {
    image:
      'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/fox-face_1f98a.png',
    name: 'Orange Fox',
    location: 'Berlin, Germany',
    date: '2019-05-30T10:05:50.999Z',
  },
  {
    image:
      'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/panda-face_1f43c.png',
    name: 'Panda Face',
    location: 'Berlin, Germany',
    date: '2019-06-09T10:05:50.999Z',
  },
  {
    image:
      'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/frog-face_1f438.png',
    name: 'Zabka',
    location: 'Berlin, Germany',
    date: '2019-06-12T10:05:50.999Z',
  },
  {
    image:
      'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/rabbit-face_1f430.png',
    name: 'Rabbit',
    location: 'Berlin, Germany',
    date: '2019-06-21T10:05:50.999Z',
  },
  {
    image:
      'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/198/cat-face_1f431.png',
    name: 'Yellow Cat',
    location: 'Berlin, Germany',
    date: '2019-07-01T10:05:50.999Z',
  },
  {
    image:
      'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/fox-face_1f98a.png',
    name: 'Orange Fox',
    location: 'Berlin, Germany',
    date: '2019-07-07T10:05:50.999Z',
  },
  {
    image:
      'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/panda-face_1f43c.png',
    name: 'Panda Face',
    location: 'Berlin, Germany',
    date: '2019-07-14T10:05:50.999Z',
  },
  {
    image:
      'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/frog-face_1f438.png',
    name: 'Zabka',
    location: 'Berlin, Germany',
    date: '2019-08-10T10:05:50.999Z',
  },
  {
    image:
      'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/198/rabbit-face_1f430.png',
    name: 'Rabbit',
    location: 'Berlin, Germany',
    date: '2019-08-11T10:05:50.999Z',
  },
];
