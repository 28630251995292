import React from 'react';
import Layer from './helpers/Layer';

import './Modal.css';

const Modal = ({ onDismiss, children }) => {
  const handleDismiss = ev => {
    if (ev.target === ev.currentTarget) {
      onDismiss && onDismiss();
    }
  };
  return (
    <Layer>
      <div className="fixed modal-overlay" onClick={handleDismiss}>
        {children}
      </div>
    </Layer>
  );
};

export default Modal;
